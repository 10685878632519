import { Box, Icon, Text } from '@oneloop/jopijs';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import copy from 'clipboard-copy';
import List from './List';
import translations from '../QuickDisplay.trans.json';
import Trans from '@/components/shared/Trans';
import style from '../QuickDisplay.module.scss';
import NoWebTooltip from './NoWebTooltip';

export default function WebSite() {
  const [showDD, setShowDD] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const contRef = useRef(null);
  const { quickData, isDevelopment, active } = useSelector(
    (state) => state.QuickDisplaySlice
  );
  const { data, web_property_url } = quickData;

  const Options = [
    {
      title: Trans(translations, 'GoToWebsite'),
      action: () => window.open(data.web_url || web_property_url, '_blank'),
    },
    {
      title: Trans(translations, 'CopyWebsiteLink'),
      action: () => {
        copy(data.web_url || web_property_url);
        setCopySuccess(true);

        setTimeout(() => {
          setCopySuccess(false);
        }, 3000);
      },
    },
  ];

  const openDD = () => {
    if (isDevelopment && !data.web_url) {
      setShowTooltip(true);
    } else {
      setShowDD(true);
    }
  };

  return (
    <Box
      className={!active ? style.noneEvents : ''}
      __css={{ position: 'relative' }}
    >
      <Box ref={contRef} className={style.itemHeader} onClick={openDD}>
        <Box
          className={`${style.iconText} ${
            !active ? style.propertyDeleted : ''
          }`}
        >
          <Icon icon="icon-sitioweb" fontSize="16px" />
          <Text variant="bodyBold.fontSize12">
            {Trans(translations, 'WebSite')}
          </Text>
        </Box>
      </Box>

      {contRef.current && (
        <NoWebTooltip
          title={Trans(translations, 'ToViewOrCopyMustHaveWebsite')}
          state={showTooltip}
          seter={setShowTooltip}
          arrowPosition={`${(contRef.current.offsetWidth - 10) / 2}px`}
        />
      )}

      <List open={showDD} seter={setShowDD} __css={{ minWidth: '179px' }}>
        {Options.map((option) => (
          <Box
            className={style.neutral2}
            as="li"
            key={option}
            onClick={option.action}
          >
            {option.title}
          </Box>
        ))}
      </List>

      <List
        open={copySuccess}
        seter={setCopySuccess}
        __css={{ borderColor: '#A2D4E5', borderRadius: '16px' }}
      >
        <Box className={style.linkCopiedWebSite}>
          <Icon icon="icon-check" fontSize="16px" />
          <Text variant="bodyBold.fontSize12">
            {Trans(translations, 'LinkCopied')}
          </Text>
        </Box>
      </List>
    </Box>
  );
}
